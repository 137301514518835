import { useCallback, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Stack,
    Button
} from "@mui/material";
import { isCompleted } from "api/endpoints";
import { Survey } from "utils/objects";
import { useNavigate } from "react-router-dom";

const Pendientes = () => {
    const navigate = useNavigate()
    const [completed, setCompleted] = useState<boolean>(false);
    const [pending, setPending] = useState<Survey[]>([])

    const fetchIsPending = useCallback(async () => {
        const data = await isCompleted();
        localStorage.setItem("user", JSON.stringify(data));
        setCompleted(data.completed);
    }, [])

    // const fetchPending = useCallback(async () => {
    //     const data = await Pending()
    //     setPending(data.pending)
    // }, [])

    // const fetchCompleted = useCallback(async () => {
    //     const data = await Completed()
    //     setCompleted(data.completed)
    // }, [])

    const completeSurvey = (id: string) => {
        navigate(`/survey/${id}`);
    }

    useEffect(() => {
        fetchIsPending();
        setPending([{
            id: "1",
            name: "Factores contextuales",
            created_at: "",
            assign_at: "",
            status: "Incompleto",
            qnumber: 49,
            questions: []
        }])
    }, []);

    return (
        <>
            <h1>Mis Cuestionarios</h1>
            <Stack spacing={2}>
                <Typography>Cuestionarios pendientes</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="pending-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Creada</TableCell>
                                <TableCell>Asignada</TableCell>
                                <TableCell>Nro de preguntas</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!completed && pending?.map((row) => (
                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.status}</TableCell>
                                    <TableCell>{row.created_at}</TableCell>
                                    <TableCell>{row.assign_at}</TableCell>
                                    <TableCell>{row.qnumber}</TableCell>
                                    <TableCell>
                                        <Button sx={{ color:"white" }} onClick={() => completeSurvey('ce')}>Completar</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </>
    )
}
export default Pendientes;