import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { PermissionUser } from "utils/objects";

const AccountData = () => {

    const [user, setUser] = useState<PermissionUser>();
    useEffect(() => {
        const user = localStorage.getItem("__permissionUser");
        if (user != null) {
            setUser(JSON.parse(user))
        }
    }, [])

    return (
        <Stack spacing={0}>
            <Typography variant="body2">
                {`${user?.username}`}
            </Typography>
            {/* <Typography variant="caption">
                {user?.}
            </Typography> */}
        </Stack>
    )
}
export default AccountData;