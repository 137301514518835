import api from "./conf";
import { CompletedResponse, LoginResponse, PendingResponse, SurveyResponse, isCompletedResponse, storeResponse } from "./responses";


interface LoginProps {
    username: string;
    password: string;
};

interface StoreProps {
    userId: number;
    answers: number[];
}

export const Login = async (args: LoginProps) => {
    const response = await api.post<LoginResponse>("/login", args);
    return response.data;
}

export const Pending = async () => {
    const response = await api.get<PendingResponse>("/pending");
    return response.data;
}

export const Completed = async () => {
    const response = await api.get<CompletedResponse>("/completed");
    return response.data;
}

export const getQuestions = async (id: string) => {
    const response = await api.get<SurveyResponse>(`/survey/${id}`);
    return response.data;
}

export const isCompleted = async () => {
    const response = await api.get<isCompletedResponse>("/survey/isCompleted");
    return response.data;
}

export const store = async (args: StoreProps) => {
    const response = await api.post<storeResponse>("/survey/store", args)
    return response.data;
}