import axios from "axios";

const baseURL = "http://ec2-18-116-21-169.us-east-2.compute.amazonaws.com:8080";

const api = axios.create({
    baseURL: baseURL,
    timeout: 180000,
    withCredentials: false,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`
    }
});

api.interceptors.request.use(function (config) {
    const token = localStorage.getItem("ACCESS_TOKEN");
    config.headers.Authorization = "Bearer " + token;
    return config;
});

export default api;