import { Box, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import StarSelector from "components/StarSelector/StarSelector";
import AudioPlayer from "react-h5-audio-player"
import 'react-h5-audio-player/lib/styles.css';
import { AppContext } from "context/AppContext";

interface StepProps {
    qn: number;
    title?: string;
    isPreamble: boolean;
    audioLink: string | undefined;
    children: JSX.Element | JSX.Element[];
}

const labels: { [index: string]: string } = {
    1: "Totalmente en desacuerdo",
    2: "En desacuerdo",
    3: "Indiferente",
    4: "De acuerdo",
    5: "Totalemente de acuerdo"
}

const getLabel = (value: number) => {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`
}

const Step = ({ qn, title, isPreamble, audioLink, children }: StepProps) => {
    const context = useContext(AppContext);
    return (
        <>
            {isPreamble ? <>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    {children}
                </Grid>
            </> : <>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="h6">{title}</Typography>
                    <AudioPlayer style={{ width: "70%" }}
                        src={audioLink}
                        showSkipControls={false}
                        showJumpControls={false}
                    />
                    {children}
                </Grid>
                <Box sx={{ width: "50%" }}>
                    <StarSelector qn={qn} addAnswer={context?.actions.addAnswer} />
                </Box>
            </>}
        </>
    )
}

export default Step;