import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import SchoolIcon from '@mui/icons-material/School';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PendingIcon from '@mui/icons-material/Pending';
import AccountData from '../components/AccountData';
import { useNavigate } from 'react-router-dom';
import { usePermission } from 'react-permission-role';
//Pages
import Establecimientos from './apps/Establecimientos';
import Alumnos from './apps/Alumnos';
import { useEffect, useState } from 'react';
import Instrumentos from './apps/Instrumentos';
import Pendientes from './apps/Pendientes';

import logo from "assets/logo-home.png"
interface optionProps {
    label: string;
    icon: JSX.Element;
    component: JSX.Element;
}

const drawerWidth = 240;


const adminOptions: optionProps[] = [
    {
        label: "Instrumentos",
        icon: <DynamicFormIcon />,
        component: <Instrumentos/>
    },
    {
        label: "Establecimientos",
        icon: <SchoolIcon />,
        component: <Establecimientos/>
    },
    {
        label: "Alumnos",
        icon: <EmojiPeopleIcon />,
        component: <Alumnos/>
    }
];

const studentOptions: optionProps[] = [
    {
        label: "Mis pendientes",
        icon: <PendingIcon/>,
        component: <Pendientes/>
    }
]


const ClippedDrawer = () => {
    const navigate = useNavigate()
    const { isAuthorized } = usePermission()
    const [options, setOptions] = useState<optionProps[]>([])
    const [selected, setSelected] = React.useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            if (await isAuthorized(["admin"])) {
                setOptions(adminOptions);
            } else {
                setOptions(studentOptions);
            }
        } 

        fetchData()
    })

    const logout = () => {
        localStorage.clear();
        window.location.reload()
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
                        <img src={logo} alt='siese-logo' style={{ width: "10%", height: "auto" }}/>
                    </Typography>
                    <div>
                        <AccountData />
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto', height: "100vh" }}>
                    <List>
                        {options.map((item, index) => (
                            <ListItem key={item.label} disablePadding>
                                <ListItemButton onClick={() => setSelected(index)}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.label} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        <ListItem key="config" disablePadding>
                            <ListItemButton onClick={() => navigate('/configuracion')}>
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Configuración" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <List sx={{ position: "absolute", bottom: 0, width: "100%" }}>
                        <ListItem key="logout" disablePadding>
                            <ListItemButton onClick={logout}>
                                <ListItemIcon>
                                    <LogoutIcon color="error" />
                                </ListItemIcon>
                                <ListItemText primary="Cerrar sesión" primaryTypographyProps={{ color: "red" }} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                {options.length > 0 && options[selected].component}
            </Box>
        </Box>
    );
}

export default ClippedDrawer;