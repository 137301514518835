import { FC } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles"

type FInputProps = {
    name: string;
} & TextFieldProps;

const STextField = styled(TextField)({
    "& label.Mui-focused" : {
        color: "#5E5B5D",
        fontWeight: 400
    },
    "& .MuiInputBase-input": {
        borderColor: "#C8D0D4"
    },
    "& .MuiInput-underline:after": {
        border: "none"
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D32F2F"
            }
        },
        "& fieldset": {
            borderColor: "#C8D0D4",
            borderRadius: 0
        },
        "&:hover fieldset": {
            border: "1px solid #C8D0D4"
        },
        "&.Mui-focused fieldset": {
            border: "1px solid #C8D0D4"
        }
    }
});

const FInput: FC<FInputProps> = ({ name, ...rest }) => {
    const {
        control,
        formState: { errors }
    } = useFormContext();

    return(
        <Controller
            control={control}
            name={name}
            defaultValue=""
            render={({field}) => (
                <STextField
                    { ...field }
                    { ...rest}
                    variant="outlined"
                    sx={{ mb: "1.5rem" }}
                    error={!!errors[name]}
                    helperText={
                        errors[name] ? (errors[name]?.message as unknown as string) : ""
                    }
                />
            )}
        />
    )
}

export default FInput;