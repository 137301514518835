import { Chip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";


const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Nombre", width: 130 },
    { field: "completed_num", headerName: "Nro de respuestas",type: "number", width: 200 },
    { field: "status", headerName: "Estado", width: 100, renderCell: (params) => {return <Chip label="Activo" color="success"/>}}
];

const rows = [
    { id: 1, name: "I1", completed_num: 132, status: "" }
];


const Instrumentos = () => {
    return (
        <>
            <h1>Instrumentos</h1>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {page: 0, pageSize: 5}
                    }
                }}
                pageSizeOptions={[5,10]}
                checkboxSelection
            />
        </>
        
    )
}
export default Instrumentos;