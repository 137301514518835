import { useState } from "react";
import { Box, Button, DialogActions, Grid, Paper, Stack, Table, TableCell, TableHead, TextField, styled } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import StyledModal from "components/StyledModal";
import { TypeOf } from "zod";
import { createEstablishmentSchema } from "schemas/createEstSchema";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import FInput from "components/FInput";
import { LoadingButton } from "@mui/lab";
import { CloudUpload } from "@mui/icons-material";

type IEst = TypeOf<typeof createEstablishmentSchema>;


const columns: GridColDef[] = [
    { field: "name", headerName: "Nombre", width: 200 },
    { field: "street", headerName: "Calle", width: 200 },
    { field: "commune", headerName: "Comuna", width: 200 },
    { field: "region", headerName: "Región", width: 200 },
    { field: "contact_name", headerName: "Nombre de contacto", width: 200 },
    { field: "email", headerName: "Correo de contacto", width: 200 },
    { field: "phone", headerName: "Nro de teléfono", width: 200 }
]

const rows = [
    { id: 1, name: "Escuela 1", street: "Calle 1", commune: "El bosque", region: "Metropolitana", contact_name: "Juan Pérez", email: "juan.perez@mail.cl", phone: "+56911111111" }
]

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Establecimientos = () => {

    const [open, setOpen] = useState<boolean>(false);
    const [openMassive, setOpenMassive] = useState<boolean>(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleMassiveOpen = () => setOpenMassive(true);
    const handleMassiveClose = () => setOpenMassive(false);

    const defaultValues: IEst = {
        name: "",
        street: "",
        commune: "",
        region: "",
        contact_name: "",
        email: "",
        phone: ""
    }

    const methods = useForm<IEst>({
        resolver: zodResolver(createEstablishmentSchema),
        defaultValues
    });

    const onSubmitHandler: SubmitHandler<IEst> = (values: IEst) => {
        console.log(values);
    }


    return (
        <>
            <h1>Establecimientos</h1>
            <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2} sx={{ float: "right" }}>
                    <Button variant="contained" onClick={handleOpen}>Agregar</Button>
                    <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                    Carga masiva
                    <VisuallyHiddenInput type="file" />
                </Button>
                </Stack>
                <DataGrid
                    columns={columns}
                    rows={rows}
                />
            </Stack>
            <StyledModal open={open} handleClose={handleClose} title="Agregar establecimiento">
                <FormProvider {...methods}>
                    <Grid
                        container
                        sx={{
                            py: '6rem',
                            px: '1rem'
                        }}
                    >
                        <Grid
                            item
                            container
                            justifyContent="center"
                            alignItems="center"
                            rowSpacing={5}
                            sx={{
                                width: "100%",
                                marginInline: "auto"
                            }}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                component="form"
                                noValidate
                                autoComplete="off"
                                onSubmit={methods.handleSubmit(onSubmitHandler)}
                            >
                                <FInput
                                    label="Nombre"
                                    type="text"
                                    name="name"
                                    focused
                                    required
                                />
                                <FInput
                                    label="Calle"
                                    type="text"
                                    name="street"
                                    focused
                                    required
                                />
                                <FInput
                                    label="Comuna"
                                    type="text"
                                    name="commune"
                                    focused
                                    required
                                />
                                <FInput
                                    label="Región"
                                    type="text"
                                    name="region"
                                    focused
                                    required
                                />
                                <FInput
                                    label="Nombre de contacto"
                                    type="text"
                                    name="contact_name"
                                    focused
                                    required
                                />
                                <FInput
                                    label="Correo de contacto"
                                    type="email"
                                    name="email"
                                    focused
                                    required
                                />
                                <FInput
                                    label="Nro de telefono"
                                    type="text"
                                    name="phone"
                                    focused
                                    required
                                />
                                <LoadingButton
                                    loading={false}
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        py: '0.8rem',
                                        mt: 2,
                                        width: '80%',
                                        marginInline: 'auto',
                                    }}
                                >
                                    Crear
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </FormProvider>
            </StyledModal>
            {/* <StyledModal open={openMassive} handleClose={handleMassiveClose} title="Carga masiva">
                <Stack spacing={2}>
                    <Item><TextField label="Nombre" /> <TextField label="email" /></Item>
                    <Item><TextField label="Nombre" /> <TextField label="email" /></Item>
                </Stack>
                <DialogActions>
                    <Button variant="contained">Crear</Button>
                </DialogActions>
            </StyledModal> */}
        </>
    )
}
export default Establecimientos;