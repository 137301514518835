import { useState } from "react";
import { AppBar, Box, Button, CssBaseline, Grid, List, Paper, Toolbar, Typography } from "@mui/material";
import AccountData from "components/AccountData";
import Stepper from "components/Stepper/Stepper";

import { preguntas } from "./preguntas";
import preamble from "assets/ce-images/1.png";
import logo from "assets/logo-home.png"
import { ContextWrapper } from "context/AppContext";

const Survey = () => {

    const [started, setStarted] = useState<boolean>(false);

    const handleStart = () => {
        setStarted(true);
    }

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
                        <img src={logo} alt="ce-logo" style={{ width: "10%", height: "auto" }} />
                    </Typography>
                    <div>
                        <AccountData />
                    </div>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                <Paper
                    square
                    elevation={0}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 50,
                        pl: 2,
                        bgcolor: 'background.default',
                    }}
                >
                </Paper>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box sx={{ height: 255, maxWidth: "50%", width: '100%', p: 2 }}>

                        {!started && <Box sx={{
                            display: "flex",
                            width: "100%"
                        }}>
                            <Grid container direction="column">
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography variant="h4">Instrucciones:</Typography>
                                    <List>
                                        <Typography sx={{ paddingTop: "10px", fontSize: "17px" }}>Contesta cada una de las preguntas de la forma más sincera posible, tus respuestas no serán mostradas a tus profes.</Typography>
                                        <Typography sx={{ paddingTop: "10px", fontSize: "17px" }}>En estos cuestionarios no hay respuestas buenas ni malas, sólo queremos conocer tu opinión.</Typography>
                                        <Typography sx={{ paddingTop: "10px", fontSize: "17px" }}>En cada pregunta, marca la opción que represente mejor cuántas veces te sucede lo que haces, sientes o piensas.</Typography>
                                    </List>
                                </Grid>
                                <Grid container justifyContent="center">
                                    <Button variant="contained" onClick={() => handleStart()}>Comenzar!</Button>
                                </Grid>

                            </Grid>
                        </Box>}

                        {started &&
                            <ContextWrapper>
                                <Stepper showProgress={true}>
                                    {preguntas}
                                </Stepper>
                            </ContextWrapper>
                        }
                    </Box>
                </Grid>
            </Box>
        </Box>
    )
}
export default Survey;