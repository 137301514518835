import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import LoginPage from 'pages/login';
import { PermissionProvider } from 'react-permission-role';
import { useEffect, useState } from 'react';
import ClippedDrawer from 'pages/dashboard';
import Survey from 'pages/survey';

const theme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "#284c64"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#007287",
                    fontFamily: ['Ubuntu', 'sans-serif'].join()
                }
            }
        }
    },
    typography: {
        fontFamily: ['Ubuntu', 'sans-serif'].join(',')
    }
});

function App() {

    const [loggedIn, setLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        const handleStorage = () => {
            const token = localStorage.getItem("ACCESS_TOKEN");
            setLoggedIn(token != null);
        }

        window.addEventListener("storage", handleStorage)
        return () => window.removeEventListener("storage", handleStorage);
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <PermissionProvider>
            <CssBaseline />
                <Routes>
                    <Route path='/' element={loggedIn ? <ClippedDrawer /> : <LoginPage />} />
                    <Route path='/survey/:id' element={<Survey/>}/>
                </Routes>
            </PermissionProvider>
        </ThemeProvider>
    );
}

export default App;
