import { object, string } from "zod";

export const createEstablishmentSchema = object({
    name: string().min(1, "Nombre requerido"),
    street: string().min(1, "Calle requerida"),
    commune: string().min(1, "Comuna requerida"),
    region: string().min(1, "Región requerida"),
    contact_name: string().min(1, "Nombre de contacto requerido"),
    email: string().min(1, "Email de contacto requerido").email("Dirección de correo inválida"),
    phone: string().min(1, "Teléfono de contacto requerido")
})