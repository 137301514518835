import { AppBar, Toolbar } from "@mui/material";

import logo from "../assets/logo-home.png"

const Header = () => {
    return (
        <AppBar>
            <Toolbar>
                <img src={logo} alt="" width={130}/>
            </Toolbar>
        </AppBar>
    );
}
export default Header;