import { StarRate, StarBorder } from "@mui/icons-material";
import "./StarButton.css";
import { AppContext } from "context/AppContext";
import { useContext } from "react";

type registerFunction = ((qn: number, answer: number) => void) | undefined;

interface StarButtonProps {
    stars: number;
    qn: number;
    addAnswer: ((qn: number, answer: number) => void) | undefined
}


const StarButton = (props: StarButtonProps) => {

    const context = useContext(AppContext)

    const registerAnswer = (ans: number) => {
        if (props.addAnswer != undefined) {
            props.addAnswer(props.qn, ans)
        }

        context?.actions.enableButton()
    }

    const getStars = () => {
        let stars = []
        for (let i = 0; i < props.stars; i++) {
            stars.push(<StarRate color="warning" />)
        }
        return stars
    }

    return (
        <label style={{ cursor:"pointer" }}><input type="radio" name="select" onClick={() => registerAnswer(getStars().length)}/>
            <span>
                {getStars()}
            </span>
        </label>
    )
}
export default StarButton