import {
    Container,
    Grid,
    Box,
    Typography,
    Stack,
    Link as MuiLink,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FC, useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FInput from 'components/FInput';
import styled from '@emotion/styled';
import { loginSchema } from 'schemas/loginSchema';
import Header from 'components/Header';
import { Login } from 'api/endpoints';
import { usePermission } from 'react-permission-role';

import './style.css'
import { VariantType, useSnackbar } from 'notistack';



//import gear from '/assets/gear.png'
type ILogin = TypeOf<typeof loginSchema>;

export const LinkItem = styled(Link)`
    text-decoration: none;
    color: #3683DC;
    &:hover {
        text-decoration: underline;
        color: #5EA1B6;
    }
`;

const LoginPage: FC = () => {

    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const { setUser } = usePermission()
    const defaultValues: ILogin = {
        username: "",
        password: ""
    };

    const methods = useForm<ILogin>({
        resolver: zodResolver(loginSchema),
        defaultValues
    });

    const handleErrorSnack = (variant: VariantType) => () => {
        enqueueSnackbar("Hubo un error inesperado. [ERR_NET]", { variant });
    }

    const onSubmitHandler: SubmitHandler<ILogin> = (values: ILogin) => {
        //console.log(values)
        Login({ username: values.username, password: values.password })
            .then((r) => {
                //setUser(r.user);
                console.log(r);
                localStorage.setItem("ACCESS_TOKEN", r.token);
                localStorage.setItem("__permissionUser", JSON.stringify(r.__permissionUser))
                window.dispatchEvent(new Event("storage"))
                //navigate("/main")
            }).catch((e: Error) => {
                console.log(e.message);
                handleErrorSnack("error");
            })
    }

    return (
            <Container
                maxWidth={false}
                sx={{ height: '100vh', backgroundColor: { xs: '#fff', md: '#f4f4f4' } }}
            >
                <Header />
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    sx={{ width: '100%', height: '100%' }}
                >
                    <Grid
                        item
                        sx={{ maxWidth: '70rem', width: '100%', backgroundColor: '#fff' }}
                    >
                        <FormProvider {...methods}>
                            <Grid
                                container
                                sx={{
                                    boxShadow: { sm: '0 0 5px #ddd' },
                                    py: '6rem',
                                    px: '1rem',
                                }}
                            >
                                <Grid
                                    item
                                    container
                                    justifyContent='center'
                                    rowSpacing={5}
                                    sx={{
                                        maxWidth: { sm: '45rem' },
                                        marginInline: 'auto',
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                    >
                                        <Box
                                            display='flex'
                                            flexDirection='column'
                                            component='form'
                                            noValidate
                                            autoComplete='off'
                                            onSubmit={methods.handleSubmit(onSubmitHandler)}
                                        >
                                            {/* <img src={gear} className='rotate' alt=''/> */}
                                            <Typography
                                                variant='h6'
                                                component='h1'
                                                sx={{ textAlign: 'center', mb: '1.5rem' }}
                                            >
                                                Ingresar a SIESE
                                            </Typography>

                                            <FInput
                                                label='Ingresar nombre de usuario'
                                                type='string'
                                                name='username'
                                                focused
                                                required
                                            />
                                            <FInput
                                                type='password'
                                                label='Contraseña'
                                                name='password'
                                                required
                                                focused
                                            />

                                            <LoadingButton
                                                loading={false}
                                                type='submit'
                                                variant='contained'
                                                sx={{
                                                    py: '0.8rem',
                                                    mt: 2,
                                                    width: '80%',
                                                    marginInline: 'auto',
                                                }}
                                            >
                                                Iniciar sesión
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent='center'>
                                    <Stack sx={{ mt: '3rem', textAlign: 'center' }}>
                                        <Typography sx={{ fontSize: '0.9rem' }}>
                                            ¿Olvidó su{' '}
                                            <LinkItem to='/forgotPassword'>contraseña?</LinkItem>
                                        </Typography>
                                        {/* <Typography variant='h5'>
                                        Sitio en mantenimiento
                                    </Typography> */}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </Grid>
                </Grid>
            </Container>
    );
}

export default LoginPage;