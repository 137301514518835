import { Button, Grid, Stack, Typography } from "@mui/material"
import StarButton from "./StarButton"

interface StarSelectorProps {
    qn: number;
    addAnswer: ((qn: number, answer: number) => void) | undefined;
}

const StarSelector = ({ qn, addAnswer }: StarSelectorProps) => {
    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
                <Typography>Nunca</Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="left" alignItems="center">
                <StarButton stars={1} qn={qn} addAnswer={addAnswer} />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
                <Typography>Pocas veces</Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="left" alignItems="center">
                <StarButton stars={2} qn={qn} addAnswer={addAnswer} />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
                <Typography>A veces</Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="left" alignItems="center">
                <StarButton stars={3} qn={qn} addAnswer={addAnswer} />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
                <Typography>Muchas veces</Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="left" alignItems="center">
                <StarButton stars={4} qn={qn} addAnswer={addAnswer} />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
                <Typography>Siempre</Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="left" alignItems="center">
                <StarButton stars={5} qn={qn} addAnswer={addAnswer} />
            </Grid>
        </Grid>
    )
}
export default StarSelector