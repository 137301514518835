import React, { ReactNode, useState } from "react";

interface WrapperProps {
    children: ReactNode
}

interface StoreType {
    answers: {qn: number; answer: number}[];
    buttonEnabled: boolean;
}

interface PropType {
    store: {
        answers: {qn: number; answer: number}[];
        buttonEnabled: boolean;
    };
    actions: { 
        addAnswer: (qn: number, answer: number) => void;
        enableButton: () => void;
        disableButton: () => void;
    }
}

export const AppContext = React.createContext<PropType | null>(null);

export const ContextWrapper = ({children}: WrapperProps) => {
    const [store, setStore] = useState<StoreType>({answers: [], buttonEnabled: false});
    // const [actions, setActions] = useState({ 
    //     addAnswer: (qn: number, answer: number) => setStore((prev) => ({...prev, answers: [...prev.answers, {qn: qn, answer: answer}]})),
    //     enableButton: () => setStore({...store, buttonEnabled: true}),
    //     disableButton: () => setStore({...store, buttonEnabled: false})
    // });
    // const [actions, setActions] = useState(() => ({
    //     addAnswer: (qn: number, answer: number) =>
    //       setStore((prev) => ({
    //         ...prev,
    //         answers: [...prev.answers, { qn: qn, answer: answer }],
    //       })),
    //     enableButton: () => setStore((prev) => ({ ...prev, buttonEnabled: true })),
    //     disableButton: () => setStore((prev) => ({ ...prev, buttonEnabled: false })),
    //   }));
    const [actions, setActions] = useState(() => ({
        addAnswer: (qn: number, answer: number) => 
            setStore((prev) => {
                const updatedAns = prev.answers.map(obj => {
                    if (obj.qn === qn) {
                        return {...obj, answer: answer}
                    }
                    return obj;
                });

                const existingObj = updatedAns.find(obj => obj.qn === qn);
                if (!existingObj) {
                    updatedAns.push({qn: qn, answer: answer});
                }
                return {...prev, answers: updatedAns};
            }),
            enableButton: () => setStore((prev) => ({ ...prev, buttonEnabled: true })),
            disableButton: () => setStore((prev) => ({ ...prev, buttonEnabled: false })),
    }));

    return (
        <AppContext.Provider value={{store, actions}}>
            {children}
        </AppContext.Provider>
    );
}
