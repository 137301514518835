import Step from "components/Stepper/Step";

import i1 from "assets/ce-images/1.png";
import i2 from "assets/ce-images/2.png";
import i3 from "assets/ce-images/3.png";
import i4 from "assets/ce-images/4.png";
import i5 from "assets/ce-images/5.png";
import i6 from "assets/ce-images/6.png";
import i7 from "assets/ce-images/7.png";
import i8 from "assets/ce-images/8.png";
import i9 from "assets/ce-images/9.png";
import i10 from "assets/ce-images/10.png";
import i11 from "assets/ce-images/11.png";
import i12 from "assets/ce-images/12.png";
import i13 from "assets/ce-images/13.png";
import i14 from "assets/ce-images/14.png";
import i15 from "assets/ce-images/15.png";
import i16 from "assets/ce-images/16.png";
import i17 from "assets/ce-images/17.png";
import i18 from "assets/ce-images/18.png";
import i19 from "assets/ce-images/19.png";
import i20 from "assets/ce-images/20.png";
import i21 from "assets/ce-images/21.png";
import i22 from "assets/ce-images/22.png";
import i23 from "assets/ce-images/23.png";
import i24 from "assets/ce-images/24.png";
import i25 from "assets/ce-images/25.png";
import i26 from "assets/ce-images/26.png";
import i27 from "assets/ce-images/27.png";
import i28 from "assets/ce-images/28.png";
import i29 from "assets/ce-images/29.png";
import i30 from "assets/ce-images/30.png";
import i31 from "assets/ce-images/31.png";
import i32 from "assets/ce-images/32.png";
import i33 from "assets/ce-images/33.png";
import i34 from "assets/ce-images/34.png";
import i35 from "assets/ce-images/35.png";
import i36 from "assets/ce-images/36.png";
import i37 from "assets/ce-images/37.png";
import i38 from "assets/ce-images/38.png";
import i39 from "assets/ce-images/39.png";
import i40 from "assets/ce-images/40.png";
import i41 from "assets/ce-images/41.png";
import i42 from "assets/ce-images/42.png";
import i43 from "assets/ce-images/43.png";
import i44 from "assets/ce-images/44.png";
import i45 from "assets/ce-images/45.png";
import i46 from "assets/ce-images/46.png";
import i47 from "assets/ce-images/47.png";
import i48 from "assets/ce-images/48.png";
import i49 from "assets/ce-images/49.png";


const buildStep = (qn:number, title: string, isPreamble: boolean, image: string, audio?: string) => {
    return (
        <Step qn={qn} title={title} isPreamble={isPreamble} key={`key-${title}`} audioLink={audio}>
            <img src={image} alt={`img-${title}`} style={{ width: "70%", height: "auto" }}/>
        </Step>
    )
}

export const preguntas = [
    buildStep(1, "1. Me siento parte de esta escuela.", false, i1, "https://sarian.cl/audio/1%20grabaci%c3%b3n.mp3"),
    buildStep(2, "2. Antes de una prueba, organizo cómo estudiar los contenidos.", false, i2, "https://sarian.cl/audio/2%20grabaci%c3%b3n.mp3"),
    buildStep(3, "3. Estoy todo el tiempo en la clase sin tratar de escaparme.", false, i3, "https://sarian.cl/audio/3%20grabaci%c3%b3n.mp3"),
    buildStep(4, "4. Si necesito salir de la sala, pido permiso al profesor/a.", false, i4, "https://sarian.cl/audio/4%20grabaci%c3%b3n.mp3"),
    buildStep(5, "5. Me siento cómodo/a en esta escuela tal como soy.", false, i5, "https://sarian.cl/audio/5%20grabaci%c3%b3n.mp3"),
    buildStep(6, "6. Busco información en internet o en libros para entender mejor lo que me enseña el profesor.", false, i6, "https://sarian.cl/audio/6%20grabaci%c3%b3n.mp3"),
    buildStep(7, "7. La mayoría de las cosas que aprendo en la escuela me sirven para la vida.", false, i7, "https://sarian.cl/audio/7%20grabaci%c3%b3n.mp3"),
    buildStep(8, "8. La mayoría de los profesores/as se preocupan de enseñarnos cosas que nos sirvan para la vida.", false, i8, "https://sarian.cl/audio/8%20grabaci%c3%b3n.mp3"),
    buildStep(9, "9. Llego a tiempo a clases.", false, i9, "https://sarian.cl/audio/9%20grabaci%c3%b3n.mp3"),
    buildStep(10, "10. Me preocupo de entender lo más posible las actividades que hago en la escuela.", false, i10, "https://sarian.cl/audio/10%20grabaci%c3%b3n.mp3"),
    //buildStep(11, "11. Llaman a mis apoderados porque me porto muy bien.", false, i11, "https://sarian.cl/audio/11%20grabaci%c3%b3n.mp3"),
    buildStep(11, "11. Me siento orgulloso/a de estar en esta escuela.", false, i12, "https://sarian.cl/audio/11%20grabaci%c3%b3n.mp3"),
    buildStep(12, "12. Después de una prueba, reviso si mis respuestas fueron las correctas.", false, i13, "https://sarian.cl/audio/12%20grabaci%c3%b3n.mp3"),
    buildStep(13, "13. Sé cómo cambiar mi forma de estudiar para tener mejores notas.", false, i14, "https://sarian.cl/audio/13%20grabaci%c3%b3n.mp3"),
    buildStep(14, "14. Para mí es muy importante lo que hacemos en la escuela.", false, i15, "https://sarian.cl/audio14%20grabaci%c3%b3n.mp3"),
    buildStep(15, "15. Me porto bien en clases.", false, i16, "https://sarian.cl/audio/15%20grabaci%c3%b3n.mp3"),
    buildStep(16, "16. Cuando comienzo una tarea, recuerdo lo que he aprendido antes porque eso me ayuda a hacerla mejor.", false, i17, "https://sarian.cl/audio/16%20grabaci%c3%b3n.mp3"),
    buildStep(17, "17. Cuando estudio, anoto palabras nuevas, dudas o ideas.", false, i18, "https://sarian.cl/audio/17%20grabaci%c3%b3n.mp3"),
    buildStep(18, "18. Me tratan con respeto en esta escuela.", false, i19, "https://sarian.cl/audio/18%20grabaci%c3%b3n.mp3"),
    buildStep(19, "19. Para mí es importante esforzarse por entender bien las tareas y la materia.", false, i20, "https://sarian.cl/audio/19%20grabaci%c3%b3n.mp3"),
    buildStep(20, "20. Sé cómo utilizar diferentes formas de estudio para realizar bien mis tareas (por ejemplo, leer la materia, estudiar en grupos, etc.).", false, i21, "https://sarian.cl/audio/20%20grabaci%c3%b3n.mp3"),
    buildStep(21, "21. Lo que aprendo en clases es importante para lograr lo que quiero en el futuro.", false, i22, "https://sarian.cl/audio/21%20grabaci%c3%b3n.mp3"),
    buildStep(22, "22. Me llevo bien con mis compañeros/as en la sala.", false, i23, "https://sarian.cl/audio/22%20grabaci%c3%b3n.mp3"),
    buildStep(23, "23. Después de terminar mis tareas reviso si están bien hechas.", false, i24, "https://sarian.cl/audio/23%20grabaci%c3%b3n.mp3"),
    buildStep(24, "24. Cuando termino una tarea, pienso si la hice bien.", false, i25, "https://sarian.cl/audio/24%20grabaci%c3%b3n.mp3"),
    buildStep(25, "25. Pongo atención a los comentarios que los/as profesores/as hacen sobre mis trabajos.", false, i26, "https://sarian.cl/audio/25%20grabaci%c3%b3n.mp3"),
    buildStep(26, "26. Siento que soy importante para la escuela.", false, i27, "https://sarian.cl/audio/26%20grabaci%c3%b3n.mp3"),
    buildStep(27, "27. Me porto muy bien por lo que no me mandan a la oficina del director/a o del inspector/a general.", false, i28, "https://sarian.cl/audio/27%20grabaci%c3%b3n.mp3"),
    buildStep(28, "28. Me siento bien en esta escuela.", false, i29, "https://sarian.cl/audio/28%20grabaci%c3%b3n.mp3"),
    buildStep(29, "29. Hablo con alguien de mi familia sobre lo que hago en la escuela.", false, i30, "https://sarian.cl/audio/29%20grabaci%c3%b3n.mp3"),
    buildStep(30, "30. Mi familia me anima a trabajar bien en la escuela.", false, i31, "https://sarian.cl/audio/30%20grabaci%c3%b3n.mp3"),
    buildStep(31, "31. Cuando tengo un problema, recibo ayuda de mi familia.", false, i32, "https://sarian.cl/audio/31%20grabaci%c3%b3n.mp3"),
    buildStep(32, "32. Mi familia me ayuda a hacer las tareas.", false, i33, "https://sarian.cl/audio/32%20grabaci%c3%b3n.mp3"),
    buildStep(33, "33. Mi familia me felicita cuando me va bien en la escuela.", false, i34, "https://sarian.cl/audio/33%20grabaci%c3%b3n.mp3"),
    buildStep(34, "34. Mis profesores/as quieren que aprenda mucho.", false, i35, "https://sarian.cl/audio/34%20grabaci%c3%b3n.mp3"),
    buildStep(35, "35. Cuando tengo un problema, recibo ayuda de algún/a profesor/a.", false, i36, "https://sarian.cl/audio/35%20grabaci%c3%b3n.mp3"),
    buildStep(36, "36. Los/as profesores/as me animan a realizar nuevamente una tarea si me equivoco.", false, i37, "https://sarian.cl/audio/36%20grabaci%c3%b3n.mp3"),
    buildStep(37, "37. Los/as profesores/as se interesan por mí y me ayudan si tengo problemas para hacer las tareas.", false, i38, "https://sarian.cl/audio/37%20grabaci%c3%b3n.mp3"),
    buildStep(38, "38. Me llevo bien con mis profesores/as. ", false, i39, "https://sarian.cl/audio/38%20grabaci%c3%b3n.mp3"),
    buildStep(39, "39. Mis profesores/as se preocupan por cómo me siento.", false, i40, "https://sarian.cl/audio/39%20grabaci%c3%b3n.mp3"),
    buildStep(40, "40. En mi escuela, los profesores/as y otros/as adultos/as tratan a todos los estudiantes con respeto.", false, i41, "https://sarian.cl/audio/40%20grabaci%c3%b3n.mp3"),
    buildStep(41, "41. En esta escuela, se valora la participación y la opinión de todos/as.", false, i42, "https://sarian.cl/audio/41%20grabaci%c3%b3n.mp3"),
    buildStep(42, "42. Mis compañeros/as me apoyan y se preocupan por mí.", false, i43, "https://sarian.cl/audio/42%20grabaci%c3%b3n.mp3"),
    buildStep(43, "43. Me siento seguro/a con mis compañeros/as.", false, i44, "https://sarian.cl/audio/43%20grabaci%c3%b3n.mp3"),
    buildStep(44, "44. Mis compañeros/as de la escuela son  importantes para mí.", false, i45, "https://sarian.cl/audio/44%20grabaci%c3%b3n.mp3"),
    buildStep(45, "45. Me llevo bien con mis compañeros/as de curso.", false, i46, "https://sarian.cl/audio/45%20grabaci%c3%b3n.mp3"),
    buildStep(46, "46. Siento que soy importante para mis compañeros/as de la escuela.", false, i47, "https://sarian.cl/audio/46%20grabaci%c3%b3n.mp3"),
    buildStep(47, "47. En mi escuela, al menos un/a compañero/a me apoya con las tareas difíciles.", false, i48, "https://sarian.cl/audio/47%20grabaci%c3%b3n.mp3"),
    buildStep(48, "48. Cuándo no entiendo mis compañeros/as me explican.", false, i49, "https://sarian.cl/audio/48%20grabaci%c3%b3n.mp3"),
];