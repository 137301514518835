import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import Progressbar from "components/Progressbar";
import { ReactElement, useContext, useEffect, useState } from "react";
import { AppContext } from "context/AppContext";
import { store } from "api/endpoints";
import { isCompletedResponse } from "api/responses";

interface StepperProps {
    showProgress: boolean;
    children: JSX.Element | JSX.Element[];
}

const Stepper = ({ showProgress, children }: StepperProps) => {

    const context = useContext(AppContext);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [Child, setChild] = useState<React.JSX.Element>();
    const [isLast, setIsLast] = useState<boolean>(false);
    const [finished, setFinished] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);


    useEffect(() => {
        if (children instanceof Array) {
            activeStep < children.length - 1 ? setIsLast(false) : setIsLast(true)
            setChild(children[activeStep])
        }

    }, [activeStep, finished])

    const logout = () => {
        localStorage.clear();
        window.location.href = ""
    }

    const handleNext = () => {
        console.log(context?.store.answers)
        if (children instanceof Array) {
            if (activeStep < children.length - 1) {
                setActiveStep(activeStep + 1)
            }
        }
        context?.actions.disableButton()
    }

    const sendAnswers = () => {
        let array: number[] = [];
        let user: isCompletedResponse = JSON.parse(localStorage.getItem("user") || "")
        setOpen(true)
        context?.store.answers.forEach((item) => {
            array.push(item.answer)
        })
        store({ userId: user.user_id, answers: array })
            .then((r) => {
                setOpen(false);
                console.log(r);
                setFinished(true);
            }).catch((e: Error) => {
                console.log(e)
                setOpen(false);
            })
    }

    const neutralizeBack = () => {
        window.history.pushState(null, "", window.location.href)
    }

    const handleFinish = () => {
        setActiveStep(activeStep + 1)
        console.log(context?.store.answers)
        neutralizeBack()
        sendAnswers()

    }
    return (
        <>
            <Box sx={{
                display: "flex",
                width: "100%"
            }}>
                {
                    !finished ? <Grid container direction="column">
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {children instanceof Array ? <Progressbar value={activeStep} total={children.length} /> : <></>}
                            {children instanceof Array ? Child : children}
                        </Grid>
                        <Grid container justifyContent="center" sx={{ marginTop: "30px" }}>
                            {/* <Button variant="contained" onClick={() => handlePrev()}>Anterior</Button> */}
                            {isLast ? <Button variant="contained" disabled={!context?.store.buttonEnabled} onClick={() => handleFinish()}>Finalizar</Button> : <Button disabled={!context?.store.buttonEnabled} variant="contained" onClick={() => handleNext()}>Siguiente</Button>}
                        </Grid>
                    </Grid> : <Grid container direction="column">
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="h3" sx={{ paddingBottom: "50px" }}>¡Muchas gracias por participar!</Typography>
                            <Button variant="contained" onClick={logout}>Cerrar sesión</Button>
                        </Grid>
                    </Grid>
                }
            </Box>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </>
    );
}

export default Stepper;